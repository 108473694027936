<template>
  <div class="knowledge-experience">
    <v-card
      :color="settings || !editable ? 'backgroundDark' : 'element'"
      :class="!settings ? 'mx-auto' : null"
      max-width="600"
      :elevation="settings || !editable ? 0 : 1"
    >
      <v-card-title
        v-if="title"
        v-text="$t(title)"
        class="title-text"
      ></v-card-title>
      <p
        v-if="subtitle"
        :class="!settings ? 'mx-sm-4' : null"
      >
        {{ $t(subtitle) }}
      </p>
      <v-list
        :color="settings || !editable ? 'backgroundDark' : 'element'"
        :class="!settings ? 'mx-sm-4' : null"
      >
        <template v-for="section in sections">

          <!-- MAIN KEYS -->
          <div
           :key="section"
           class="d-flex justify-space-between caption"
          >
            <p class="section-title">
              {{ $t(`onboarding.investmentSurvey.views.${section}.title`) }}
            </p>
            <div
              v-if="section === 'products'"
              class="d-flex"
            >
              <p>{{ $t(`onboarding.investmentSurvey.views.overview.status`) }}</p>
              <p v-if="editable" class="ml-3">{{ $t(`onboarding.investmentSurvey.views.overview.edit`) }}</p>
            </div>
          </div>
          <!-- MAIN KEYS -->
           <v-divider :key="`divider${section}`"></v-divider>

          <v-card-text
            class="pa-0"
            :key="`card-text${section}`"
          >
            <v-list-item
              v-for="(item, index) in sectionObject(section)"
              :key="`${index}`"
            >
              <v-row>
                <v-col cols="7" :sm="editable ? 9 : 10">
                  <v-list-item-content>

                    <!-- KEYS -->
                    <v-list-item-title
                      class="title-text"
                      v-html="$t(`onboarding.investmentSurvey.${section}.${item.type}`)">
                    </v-list-item-title>
                    <!-- KEYS -->

                    <!-- VALUES -->
                    <v-slider
                      :key="`slider${index}`"
                      :value="checkExperience(sliderValue(section, index), item.type)"
                      :tick-labels="tickLabels(item.type)"
                      :max="sliderLength(item.type)"
                      step="1"
                      ticks="always"
                      tick-size="4"
                      readonly
                      class="small-text"
                    ></v-slider>
                    <!-- VALUES -->

                  </v-list-item-content>
                </v-col>

                <!-- EDIT ACTION BUTTON -->
                <v-col
                  cols="5" :sm="editable ? 3 : 2"
                  align-self="center"
                >
                  <div class="d-flex align-center justify-end">
                    <div v-if="section === 'products'" :class="editable ? 'mr-8' : 'mr-2'">
                      <template>
                        <v-img
                          :src="
                            require(`@/assets/icons/svg/checkbox-${investmentExperience[index].knowledge ? 'true' : 'false'}.svg`)
                          "
                        />
                      </template>
                    </div>
                    <div v-if="editable">
                      <v-btn
                        icon
                        @click="edit(item, section)"
                      >
                        <v-icon
                          color="primary"
                        >
                          mdi-pencil-outline
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
                <!-- <v-col v-if="editable" cols="1" align-self="center">
                  <v-list-item-action class="ml-9">
                    <v-btn
                      icon
                      @click="edit(item, section)"
                    >
                      <v-icon
                        color="primary"
                      >
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-col> -->
                <!-- EDIT ACTION BUTTON -->
              </v-row>
            </v-list-item>
          </v-card-text>

        </template>
        <div
          :class="[
            settings
            ? 'disabled-settings backgroundDark'
            : editable
            ? 'disabled-onboarding element'
            : 'disabled-subscription backgroundDark',
          ]"
        />
      </v-list>

      <div
        v-if="!editable || settings"
        :class="[!settings ? 'mx-4' : null, 'pb-2']"
      >
        <CardNav
          :nextText="nextText"
          :prevText="prevText"
          @next="next"
          @previous="previous"
          :nextLoading="loading"
          :prevDisabled="loading || prevDisabled"
          :nextDisabled="nextDisabled"
        />
      </div>

    </v-card>
    <Modal
      :dialog="isModalOpen"
      @close="closeModal"
      :title="
        editData.section === 'products'
        ? $t(`onboarding.investmentSurvey.products.${editData.type}`)
        : $t(`onboarding.investmentSurvey.services.${editData.type}`)
      "
    >
      <template v-slot:content>
        <Edit
          :editData="editData"
          :settings="settings"
          :resetData="resetData"
          @close="closeModal"
          @clear="$emit('clear')"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapDbEnumToFrontendValue } from '@/mappings/onboarding/investmentSurvey';
import Modal from '@/components/Shared/Modal.vue';
import Edit from '@/views/Onboarding/InvestmentSurvey/Edit.vue';
import CardNav from '@/components/Shared/CardNav.vue';

export default {
  name: 'KnowledgeExperience',
  components: {
    Modal,
    Edit,
    CardNav,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    settings: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    nextDisabled: {
      type: Boolean,
      default: false,
    },
    prevDisabled: {
      type: Boolean,
      default: false,
    },
    resetData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sections: ['products', 'services'],
      isModalOpen: false,
      editData: {},
    };
  },
  computed: {
    ...mapGetters('user', [
      'user',
    ]),
    ...mapGetters('onboarding', [
      'experiences',
      'transactionNumber',
      'transactionVolume',
    ]),
    investmentExperience() {
      return Object.values(this.$store.state.user.data.investmentSurvey.investmentExperience).flat(1);
    },
    serviceExperience() {
      return [
        {
          experience: this.$store.state.user.data.investmentSurvey.serviceExperience.independent,
          type: 'independent',
        },
        {
          experience: this.$store.state.user.data.investmentSurvey.serviceExperience.investmentConsultancy,
          type: 'investmentConsultancy',
        },
        {
          experience: this.$store.state.user.data.investmentSurvey.serviceExperience.investmentManagement,
          type: 'investmentManagement',
        },
        {
          experience: this.$store.state.user.data.investmentSurvey.avgTransactionValue,
          type: 'avgTransactionValue',
        },
        {
          experience: this.$store.state.user.data.investmentSurvey.avgYearlyTransactions,
          type: 'avgYearlyTransactions',
        },
      ];
    },
    prevText() {
      if (this.settings) {
        return this.$t('settings.knowledgeAndExperience.reset');
      }
      return this.$t('subscription.navigation.yes');
    },
    nextText() {
      if (this.settings) {
        return this.$t('settings.knowledgeAndExperience.save');
      }
      return this.$t('subscription.navigation.no');
    },
  },
  methods: {
    sectionObject(section) {
      if (section === 'products') {
        return this.investmentExperience;
      }
      return this.serviceExperience;
    },
    checkExperience(data, item) {
      if (item === 'avgTransactionValue' || item === 'avgYearlyTransactions') {
        return mapDbEnumToFrontendValue[item][data];
      }
      return mapDbEnumToFrontendValue.rangeOfExperience[data];
    },
    sliderValue(section, index) {
      if (section === 'products') {
        return this.investmentExperience[index].experience;
      }
      return this.serviceExperience[index].experience;
    },
    tickLabels(key) {
      if (key === 'avgYearlyTransactions') {
        return this.transactionNumber.map((e) => e.text);
      }
      if (key === 'avgTransactionValue') {
        return this.transactionVolume.map((e) => e.text);
      }
      return this.experiences.map((e) => e.text);
    },
    sliderLength(item) {
      if (item === 'avgTransactionValue' || item === 'avgYearlyTransactions') {
        return Object.keys(mapDbEnumToFrontendValue[item]).length - 1;
      }
      return Object.keys(mapDbEnumToFrontendValue.rangeOfExperience).length - 1;
    },
    edit(item, section) {
      this.editData = {
        ...item,
        section,
      };
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    async next() {
      this.$emit('next');
    },
    async previous() {
      this.$emit('previous');
    },
  },
};
</script>

<style lang="scss">
.knowledge-experience {
  .v-card__text {
    padding: 0 !important;
  }
  .slider {
    width: 100px;
    border: 2px solid red;
  }
  .section-title {
    z-index: 3;
  }
  .disabled-settings {
    position: absolute;
    top: 50px;
    width: 75%;
    height: 95%;
    opacity: 0.4;
  }
  .disabled-subscription {
    position: absolute;
    top: 170px;
    width: 100%;
    height: 84%;
    opacity: 0.4;
  }
  .disabled-onboarding {
    position: absolute;
    top: 170px;
    width: 75%;
    height: 85%;
    opacity: 0.4;
  }
  .small-text {
    font-size: 13px;
  }
  .v-slider__tick-label {
    margin-top: 10px !important;
  }
  .title-text {
    word-wrap: break-word;
    word-break: break-word;
    white-space: initial;
  }
}
@media (max-width: 510px) {
  .disabled-onboarding {
    top: 220px !important;
    width: 50% !important;
  }
}
@media (max-width: 550px) {
  .v-slider__tick-label {
    font-size: 5px !important;
  }
}
</style>
